<template>
  <div class='mobile-user-auth-tabs'>
    <ul>
      <li v-for="(item, i) in tabs" :key="i">
        <router-link :to="item.link" >{{ item.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MobileUserAuthTabs',
  props: {},
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {
    tabs() {
      return [
        {
          title: this.$t('pages.registration.title'),
          link: '/users/registration',
        },
        {
          title: this.$t('pages.login.title'),
          link: '/users/login',
        },
      ];
    },
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.mobile-user-auth-tabs {
  width: 100%;
  border-bottom: 2px solid $mobileMenuBorderColor;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 20px;
    margin: 0;
    list-style-type: none;
  }

  li a {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    color: $globalFontColor;
  }

  a.router-link-exact-active {
    color: $primaryBlue;
  }
}
</style>
